<template>
  <VTradeProcedureSidebar
    :title="title"
    tabs="userTrade"
  >
    <template v-slot:header>
      <div class="admin__button">
        <VToggleDisplay
          v-if="isToggleDisplay"
          :class="['mr-20', classToggleDisplay]"
        />
      </div>
    </template>
  </VTradeProcedureSidebar>
</template>

<script>
import VTradeProcedureSidebar from '@/components/views/TradeProcedure/VTradeProcedureSidebar'
import VToggleDisplay from '@/components/ui/buttons/VToggleDisplay'
export default {
  components: {
    VTradeProcedureSidebar,
    VToggleDisplay
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isToggleDisplay: {
      type: Boolean,
      required: false,
      default: true
    },
    classToggleDisplay: {
      type: String,
      required: false
    }
  },
  setup () {
  }
}
</script>

<style>
.admin__button {
  display: flex;
}
</style>
